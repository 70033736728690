<script>
  export let num = 0;
  export let max = 100;

  export let inverted = false;
  export let disabled = false;
</script>

<style>
  input[type="range"] {
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: 1px solid white;

    /*required for proper track sizing in FF*/
    width: 100px;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100px;
    height: 2px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #c45a41;
    margin-top: -4px;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }

  input[type="range"]::-moz-range-track {
    width: 100px;
    height: 2px;
    background: #ddd;
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-moz-range-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #c45a41;
  }

  /*hide the outline behind the border*/
  input[type="range"]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
  }

  input[type="range"]::-ms-track {
    width: 100px;
    height: 2px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #777;
    border-radius: 10px;
  }
  input[type="range"]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  input[type="range"]::-ms-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #c45a41;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #888;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ccc;
  }

  input[type="range"]:disabled {
    background: none;
  }
  input[type="range"]:disabled::-webkit-slider-thumb {
    background-color: #bcbcbc;
  }
  input[type="range"]:disabled::-moz-range-thumb {
    background-color: #bcbcbc;
  }
  input[type="range"]:disabled::-ms-thumb {
    background-color: #bcbcbc;
  }
</style>

{#if inverted}
  <input
    type="range"
    min="0"
    {max}
    bind:value={num}
    {disabled}
    style="direction: rtl;" />
{:else}
  <input type="range" min="0" {max} bind:value={num} {disabled} />
{/if}
