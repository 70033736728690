<script>
  import { onMount } from "svelte";
  import zoomLevel from "@/stores/zoomLevel";
  import zoomMaxLevel from "@/stores/zoomMaxLevel";

  export function appendNode(node) {
    gridElement.appendChild(node);
  }

  export function clear() {
    for (
      let child = gridElement.lastChild;
      child;
      child = gridElement.lastChild
    ) {
      gridElement.removeChild(child);
    }
  }

  let gridElement;

  const gridGapWidth = 32;
  const minGridItemWidth = 16;
  let gridPlateaus = [0];

  // Runs onMount and on window resize events
  function renderGrid() {
    gridPlateaus = calcGridPlateaus(
      gridElement.clientWidth,
      gridGapWidth,
      minGridItemWidth
    );

    $zoomMaxLevel = gridPlateaus.length - 2;
  }

  // gridWidth: Width of the grid container element; dynamically get value from DOM, and update on viewport resize
  // gapWidth: The grid-gap value from grid container element CSS
  // minItemWidth: The minimum size (width) of a grid child element
  function calcGridPlateaus(gridWidth, gapWidth, minItemWidth) {
    let plateaus = [];
    let plateau = gridWidth;

    for (let i = 1; plateau > minItemWidth; i++) {
      plateaus.push(plateau);
      plateau = (gridWidth - i * gapWidth) / (i + 1);
    }

    return plateaus.reverse();
  }

  function translateToPlateau(zoomLevel, gridPlateaus) {
    return gridPlateaus[zoomLevel];
  }

  onMount(() => {
    renderGrid();
    $zoomLevel = Math.floor($zoomMaxLevel / 2);
  });
</script>

<style>
  :root {
    --gap: 32px;
  }

  .grid-container {
    display: grid;
    grid-gap: var(--gap);
    padding: var(--gap);
  }

  .grid-container > :global(*) {
    width: 100%;
    height: auto;
  }
</style>

<svelte:window on:resize={renderGrid} />

<div
  bind:this={gridElement}
  class="grid-container"
  style="grid-template-columns: repeat(auto-fill, minmax({translateToPlateau($zoomLevel, gridPlateaus)}px,
  1fr));" />
