export function* permutationsWithRepetition(attributes, itemCount) {
  // Total number of permutations
  let totalPerms = Math.pow(attributes.length, itemCount);

  // Each counter represents an item – e.g. a SVG fill node – that can assume an attribute – e.g.
  // a color.
  let counters = [...Array(itemCount).keys()].reverse().map(i => {
    return {
      // Number of iterations on this item
      tick: 0,
      // Number of ticks before the item assumes the next attribute in the `attributes` sequence
      period: Math.pow(attributes.length, i),
      // The index of the attribute that this item assumes
      attributeIndex: 0,
    };
  });

  for (let i = 0; i < totalPerms; i++) {
    // Produce next permutation
    yield counters.map(c => attributes[c.attributeIndex]);

    // Advance counters
    for (let j = 0; j < counters.length; j++) {
      if (++counters[j].tick < counters[j].period) { continue; }
      counters[j].tick = 0;

      if (++counters[j].attributeIndex < attributes.length) { continue; }
      counters[j].attributeIndex = 0;
    }
  }
}