<script>
  export let color = "#C45A41";
</script>

<svg
  width="8"
  height="8"
  viewBox="0 0 8 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M4 0V8" stroke={color} />
  <path d="M0 4H8" stroke={color} />
</svg>
