<script>
  import Button from "@/Button.svelte";
  import Color from "@/Color.svelte";
  import IconAdd from "@/icons/IconAdd.svelte";

  import colors from "@/stores/colors.js";

  // Need to add a function to update a color at an index

  const didUpdateHexAtIndex = function didUpdateHexAtIndex(index, hex) {
    colors.updateAtIndex(index, hex);
  };

  const didRemoveColorAtIndex = function didRemoveColorAtIndex(index) {
    colors.removeAtIndex(index);
  };

  const didClickAddColor = function didClickAddColor() {
    colors.add($colors[$colors.length - 1]);
  };
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
  }
  .container :global(button) {
    margin-top: 16px;
  }

  .container > :global(.container:first-child) {
    margin-top: 0;
  }
</style>

<div class="container">
  {#each $colors as c, i}
    <Color
      hex={c}
      on:change={e => didUpdateHexAtIndex(i, e.detail)}
      on:remove={() => didRemoveColorAtIndex(i)} />
  {/each}
  <Button on:click={() => didClickAddColor()}>
    <IconAdd />
    Add Color
  </Button>
</div>
