<script>
  import { onMount, createEventDispatcher } from "svelte";

  export let thumbnail;
  // The currently selected group value of the override.
  export let selectedGroup;
  // The total number of groups available to choose from.
  export let groupCount;

  // The container for the thumbnail.
  let thumbnailContainer;

  const dispatch = createEventDispatcher();

  onMount(() => {
    configureThumbnail();
  });

  function configureThumbnail() {
    // Insert the SVG and scale it to fit inside its container
    thumbnailContainer.appendChild(thumbnail);

    thumbnail.setAttribute("width", "100%");
    thumbnail.setAttribute("height", "100%");

    // Find the pairwise ratios between the side lengths of the SVG and its contents. The smallest ratio is the scale
    // by which the contents should be transformed so that they take up as much space as possible inside the SVG.
    let scale = ["width", "height"]
      .map(
        (dimension) =>
          thumbnail.getBoundingClientRect()[dimension] /
          thumbnail.getBBox()[dimension]
      )
      .reduce((acc, cur) => Math.min(acc, cur));

    // Find the translation which when applied to the contents will center them inside the containing SVG.
    let centeringTranslation = [
      ["x", "width"],
      ["y", "height"],
    ].reduce((translation, [axis, dimension]) => {
      // - `getBoundingClientRect()` returns the extent of the SVG element itself
      // - `getBBox()` returns the extent of everything that's drawn *inside* the SVG element, i.e. what the user
      // actually sees.

      // By 'building' the translation one step at a time, we can document each step.
      translation[axis] = 0;

      // First, move all elements drawn inside the SVG to the origin (position 0 along the axis).
      // |     | * *    --->    |* *  |
      translation[axis] += -thumbnail.getBBox()[axis] * scale;

      // Then, move the elements to the center of the SVG.
      // |* *  |        --->    |  * *|
      translation[axis] += thumbnail.getBoundingClientRect()[dimension] / 2;

      // Then, move the elements in the by half of their width to align their center with the center of the
      // SVG element.
      // |  * *|        --->    | * * |     (centered!)
      translation[axis] += (-thumbnail.getBBox()[dimension] * scale) / 2;

      return translation;
    }, {});

    // Apply the scale and the translation (applied right-to-left)
    thumbnail.firstChild.style.transform = `translate(${centeringTranslation.x}px, ${centeringTranslation.y}px) scale(${scale})`;
  }
</script>

<style>
  :root {
    --height: 24px;
  }

  .container {
    display: flex;
  }

  .preview {
    padding: 4px 0 4px 0;
    width: 16px;
    min-width: 16px;
    height: var(--height);
    margin-right: 8px;
  }

  select {
    display: block;
    width: 100%;
    height: var(--height);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #e0e0e4;
    border-radius: 3px;
    font-size: 12px;
    padding: 0 8px 0 8px;
    background-image: url("/assets/icon-chevron-down.svg");
    background-repeat: no-repeat;
    background-position: right 8px center;
  }

  /* Firefox hack */
  @supports (-moz-appearance: none) {
    select {
      padding: 0 4px 0 4px;
    }
  }
</style>

<div class="container">
  <div bind:this={thumbnailContainer} class="preview" />

  <select
    bind:value={selectedGroup}
    on:change={() => dispatch('change', selectedGroup)}>
    <option value={null}>Exclude</option>
    {#each { length: groupCount } as _, i}
      <option value={i}>Group {i + 1}</option>
    {/each}
  </select>
</div>
