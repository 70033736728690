<script>
  import { onMount, onDestroy } from "svelte";
  import Grid from "@/Grid.svelte";
  import Button from "@/Button.svelte";
  import PaginationBar from "@/PaginationBar.svelte";
  import svgPermutationsStore from "@/stores/svgPermutations";

  const defaultTakeCount = 1000;

  // TODO: Arbitrary upper limit on the number of permutations that can be loaded and displayed by Polyphaser. This limit
  // is to be removed once we have better memory management.
  const showingLimit = 500000;
  function handleLoadNextPage(e) {
    if (showingCount >= showingLimit) {
      return;
    }
    renderNextPermutations();
  }

  // Receives and presents the permutations.
  let permutationsView;

  // Pagination properties
  let showingCount = 0;
  let totalCount = 0;

  // Generator that produces permutations
  let permutations;

  let unsubscribeFromPermutationsStore;

  onMount(() => {
    // As state changes depend on the view, only subscribe for updates after the component has been mounted.
    unsubscribeFromPermutationsStore = svgPermutationsStore.subscribe(
      ({ count, generator }) => {
        showingCount = 0;
        totalCount = count;
        permutations = generator;
        resetView();
        renderNextPermutations();
      }
    );
  });

  onDestroy(() => {
    unsubscribeFromPermutationsStore();
  });

  function resetView() {
    permutationsView.clear();
  }

  function renderNextPermutations(count = defaultTakeCount) {
    let i = 0;
    for (i; i < count; i++) {
      let { value: node, done } = permutations.next();
      if (done) {
        break;
      }

      permutationsView.appendNode(node);
    }

    showingCount += i;
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }
</style>

<div class="container">
  <Grid bind:this={permutationsView} />
  <PaginationBar
    {showingCount}
    {totalCount}
    on:loadnextpage={handleLoadNextPage} />
</div>
