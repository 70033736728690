// Formats a number to a string for displaying or printing with precision up to two decimal.
export default function formatNumber(n) {
  let [integerPart, fractionalPart] = n.toString().split(".");

  let formatter;
  if (fractionalPart) {
    if (fractionalPart.indexOf('e') !== -1) {
      formatter = formatScientific;
    } else {
      formatter = formatFraction;
    }
  } else {
    formatter = formatInteger;
  }

  return formatter(n);
}

function formatInteger(i) {
  let [integerPart] = i.toString().split('.');

  if (Math.abs(i) < 10000) {
    return integerPart;
  }

  return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatFraction(f) {
  let [integerPart, fractionalPart] = f.toString().split('.');

  let formattedIntegerPart = formatInteger(f);
  if (!fractionalPart) {
    return formattedIntegerPart;
  }

  let formattedFractionalPart;
  let firstTwoFractionals = fractionalPart.substring(0, 2);
  let thirdFractional = fractionalPart[2];

  if (firstTwoFractionals.length === 1) {
    formattedFractionalPart = firstTwoFractionals + "0";
  } else if (!!thirdFractional && thirdFractional >= 5) {
    formattedFractionalPart = (Number(firstTwoFractionals) + 1).toString();
  } else {
    formattedFractionalPart = firstTwoFractionals;
  }

  return formattedIntegerPart + '.' + formattedFractionalPart;
}

// On the format '3.21e+116', meaning 3.21 * Math.pow(10, 116).
function formatScientific(s) {
  let [factorPart, exponentPart] = s.toString().split('e');
  let formattedFactorPart = formatFraction(factorPart);

  return formattedFactorPart + 'e' + exponentPart;
}