<script>
  import PermutationsOverlay from "@/PermutationsOverlay.svelte";
  import WelcomeOverlay from "@/WelcomeOverlay.svelte";

  import prototypeStore from "@/stores/prototype";
  import canvasActive from "@/stores/canvasActive";
  import shade from "@/stores/shade";

  let unsubscribeFromPrototypeStore = prototypeStore.subscribe(value => {
    if (!value) {
      return;
    }
    $canvasActive = true;
    unsubscribeFromPrototypeStore();
  });
</script>

<style>
  #canvas {
    background: #f1f1f3;
    padding: 16px;
    width: 100%;
    height: 100%;
    min-height: 308px;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    position: relative;
  }

  #canvas.active {
    padding: 0;
    display: block;
  }
</style>

<div
  id="canvas"
  class:active={$canvasActive}
  style="background-color: hsl(0, 0%, {$shade}%);">
  {#if $canvasActive}
    <PermutationsOverlay />
  {:else}
    <WelcomeOverlay />
  {/if}
</div>
