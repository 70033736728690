const hasFillSelector = '*[fill]:not([fill="none"])';
const hasStrokeSelector = '*[stroke]:not([stroke="none"])';

/**
 * Confirms that a string contains a valid SVG definition.
 * 
 * Note: looks for `parsererror` tags. If present, the SVG definition is considered invalid –
 * even if the SVG definition is valid.
 *
 * @param svgString contains the SVG definition to validate.
 * @returns `true` if the string contains a valid SVG definition.
 */
export const isValidSVGDefinition = function isValidSVGDefinition(svgString) {
  let svg = parseToSVGDocumentElement(svgString);

  return svg.getElementsByTagName('parsererror').length === 0;
}; 

export const fillElements = function fillElements(svgDoc) {
  return svgDoc.querySelectorAll(hasFillSelector);
};

export const strokeElements = function strokeElements(svgDoc) {
  return svgDoc.querySelectorAll(hasStrokeSelector);
};

export function parseToSVGDocumentElement(svgCode) {
  return new DOMParser().parseFromString(svgCode, 'image/svg+xml').documentElement;
};
