<script>
  import Override from "@/Override.svelte";
  import overrides from "@/stores/overrides";
  import prototypeStore from "@/stores/prototype";
  import { fillElements, strokeElements } from "@/helpers/svgs";
  import { onMount, onDestroy } from "svelte";

  let thumbnails;
  $: if ($prototypeStore) {
    thumbnails = thumbnailsFromPrototype($prototypeStore);
  }

  function thumbnailsFromPrototype(prototype) {
    let fillThumbnails = [...fillElements(prototype)]
      .map((e) => e.cloneNode(true))
      .map(styleAsFillThumbnail)
      .map(wrapInSvg);

    let strokeThumbnails = [...strokeElements(prototype)]
      .map((e) => e.cloneNode(true))
      .map(styleAsStrokeThumbnail)
      .map(wrapInSvg);

    return [...fillThumbnails, ...strokeThumbnails];
  }

  function wrapInSvg(element) {
    let svgElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    svgElement.appendChild(element);

    return svgElement;
  }

  function styleAsFillThumbnail(element) {
    element.setAttribute("stroke", "none");
    element.setAttribute("stroke-width", "0");
    element.setAttribute("fill", "rgba(196, 90, 65, 0.32)");

    return element;
  }

  function styleAsStrokeThumbnail(element) {
    element.setAttribute("stroke", "#C45A41");
    element.setAttribute("stroke-width", "1");
    element.setAttribute("fill", "none");

    return element;
  }
</script>

<style>
  .list > :global(.container) {
    margin-bottom: 8px;
  }
  .list > :global(.container):last-child {
    margin-bottom: 0;
  }
</style>

{#if $prototypeStore}
  <div class="list">
    {#each $overrides as o, i}
      <Override
        thumbnail={thumbnails[i]}
        selectedGroup={o}
        groupCount={$overrides.length}
        on:change={(e) => overrides.updateAtIndex(i, e.detail)} />
    {/each}
  </div>
{:else}
  <p class="grey">Add an SVG to initiate overrides.</p>
{/if}
