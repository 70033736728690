<script>
  import Button from "@/Button.svelte";
  import IconAdd from "@/icons/IconAdd.svelte";
  import { isValidSVGDefinition } from "@/helpers/svgs";
  import prototypeStore from "@/stores/prototype";

  // Invisible file input element for loading files
  let fileInputElement;

  // Handlers
  function didSubmitFile(e) {
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    // Reads user-submitted file
    let fileReader = new FileReader();
    fileReader.onload = _ => {
      if (!isValidSVGDefinition(fileReader.result)) {
        alert(
          "The file did not contain a valid SVG definition. Please try again with a different file."
        );
        return;
      }

      prototypeStore.set(fileReader.result);
    };

    fileReader.readAsText(file);
  }
</script>

<Button on:click={() => fileInputElement.click()}>
  <IconAdd />
  Add an SVG File
</Button>

<!-- Invisible file input to receive click event from Button component -->
<input
  class="dn"
  type="file"
  bind:this={fileInputElement}
  on:change={didSubmitFile} />
