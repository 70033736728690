import { writable } from 'svelte/store';

import prototypeStore from '@/stores/prototype';
import { fillElements, strokeElements } from '@/helpers/svgs';

const overridesStore = writable();

overridesStore.updateAtIndex = (i, groupIndex) => overridesStore.update((value) => {
  value[i] = groupIndex;
  return value;
});

overridesStore.unsubscribe = () => {
  unsubscribeFromPrototypeStore();
  overridesStore.unsubscribe();
};

const unsubscribeFromPrototypeStore = prototypeStore.subscribe((prototype) => {
  let initialValue;
  if (prototype) {
    let elementCount = fillElements(prototype).length + strokeElements(prototype).length;
    initialValue = [...Array(elementCount).keys()];
  } else {
    initialValue = [];
  }

  overridesStore.set(initialValue);
});

export default overridesStore;