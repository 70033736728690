import { writable } from "svelte/store";

let hexStrings = [
  "#FD6165",
  "#FFB200",
  "#00D4DB",
  "#0827B6",
  "#EA8BD6",
  "#00DBB4",
];

const createColorStore = function createColorStore() {
  const { subscribe, update } = writable(hexStrings);

  return {
    subscribe,
    /**
     * Add a new hex string color to the store.
     */
    add: hex =>
      update(value => {
        return [...value, hex];
      }),
    /**
     * Update the color stored at index `i` to a new value `hex`.
     */
    updateAtIndex: (i, hex) =>
      update(value => {
        value[i] = hex;
        return value;
      }),
    /**
     * Remove the color stored at index `i` from the store.
     */
    removeAtIndex: i =>
      update(value => {
        value.splice(i, 1);
        return value;
      })
  };
};

const colors = createColorStore();

export default colors;
