<script>
  import Button from "@/Button.svelte";
  import IconCheckmark from "@/icons/IconCheckmark.svelte";
  import { createEventDispatcher } from "svelte";
  import formatNumber from "@/helpers/number-formatting";

  export let showingCount;
  export let totalCount;

  $: showingCountLabel = formatNumber(showingCount);
  $: totalCountLabel = formatNumber(totalCount);

  const dispatch = createEventDispatcher();
</script>

<style>
  #pagination-bar {
    background: #ffffff;
    height: 32px;
    min-height: 32px;
    padding: 0 16px 0px 16px;
    margin: 0 16px 16px 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e4;
    box-sizing: border-box;
    align-items: center;
    border-radius: 3px;
  }

  #pagination-bar > :global(button) {
    height: 20px;
  }
</style>

<div id="pagination-bar">
  <div class="grey">
    {#if showingCount < totalCount}
      Showing
      <span class="black">{showingCountLabel}</span>
      of
      <span class="black">{totalCountLabel}</span>
      possible permutations.
    {:else if totalCount === 0}
      No permutations to show.
    {:else if totalCount === 1}
      Showing the only possible permutation.
    {:else}
      Showing all
      <span class="black">{totalCountLabel}</span>
      possible permutations.
    {/if}
  </div>
  {#if showingCount < totalCount}
    <Button on:click={() => dispatch('loadnextpage')}>Show More</Button>
  {:else}
    <IconCheckmark />
  {/if}
</div>
