<script>
  import InputRange from "@/InputRange.svelte";
  import IconZoom from "@/icons/IconZoom.svelte";
  import IconShade from "@/icons/IconShade.svelte";
  import Hint from "@/Hint.svelte";
  import shade from "@/stores/shade";
  import canvasActive from "@/stores/canvasActive";
  import zoomLevel from "@/stores/zoomLevel";
  import zoomMaxLevel from "@/stores/zoomMaxLevel";
</script>

<style>
  #view-bar {
    background: #ffffff;
    height: 32px;
    min-height: 32px;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    box-shadow: inset 0px -1px 0px #e0e0e4;
    justify-content: space-between;
  }

  .controls,
  .sub-group {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .sub-group > :global(svg) {
    margin-right: 8px;
  }

  .sub-group.disabled {
    opacity: 0.45;
  }

  .controls > :global(div) {
    margin-right: 32px;
  }

  :global(.monotone-group > div) {
    margin-right: 8px;
  }
</style>

<div id="view-bar" class="z-2 us--n">
  <div class="controls">
    <div class="sub-group {$canvasActive ? '' : 'disabled'}">
      <IconZoom />
      <InputRange
        disabled={!$canvasActive}
        bind:num={$zoomLevel}
        max={$zoomMaxLevel} />
    </div>
    <div class="sub-group {$canvasActive ? '' : 'disabled'}">
      <IconShade />
      <InputRange disabled={!$canvasActive} inverted bind:num={$shade} />
    </div>
  </div>
</div>
