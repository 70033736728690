<script>
  export let secondary = false;
</script>

<style>
  button {
    border: none;
    border-radius: 3px;
    height: 24px;
    font-size: inherit;
    padding: 0 16px;
    margin: 0;
    transition: opacity 0.16s;
  }

  button:active {
    opacity: 0.5;
  }

  button > :global(img) {
    margin-right: 8px;
  }
</style>

{#if secondary}
  <button class="green bg-washed-green" on:click>
    <slot />
  </button>
{:else}
  <button class="red bg-washed-red" on:click>
    <slot />
  </button>
{/if}
