<script>
  import Colors from "@/Colors.svelte";
  import Overrides from "@/Overrides.svelte";

  let activeTab = "colors";
</script>

<style>
  #inspector {
    background: #ffffff;
    width: 252px;
    min-width: 252px;
    height: 100%;
    box-shadow: inset 1px 0px 0px #e0e0e0;
  }

  .tab-bar {
    height: 32px;
    display: flex;
    justify-content: space-evenly;
  }

  .tab-bar > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 0 16px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    box-shadow: inset 0px -1px 0px #e0e0e4;
    color: #9191a1;
    background: white;
  }

  .tab-content {
    padding: 16px;
    height: calc(100% - 32px);
    overflow-y: auto;
  }

  .colors > div:nth-child(1) {
    color: #000000;
    box-shadow: inset -1px 0px 0px #e0e0e4, inset 1px 0px 0px #e0e0e4;
  }

  .overrides > div:nth-child(2) {
    color: #000000;
    box-shadow: inset -1px 0px 0px #e0e0e4, -1px 0px 0px #e0e0e4;
  }
</style>

<div id="inspector">
  <div class="tab-bar us--n {activeTab}">
    <div on:click={() => (activeTab = 'colors')}>Colors</div>
    <div on:click={() => (activeTab = 'overrides')}>Overrides</div>
  </div>
  <div class="tab-content">
    {#if activeTab === 'overrides'}
      <Overrides />
    {:else if activeTab === 'colors'}
      <Colors />
    {/if}
  </div>
</div>
