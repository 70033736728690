import { derived } from 'svelte/store';

import prototypeStore from '@/stores/prototype';
import colorsStore from '@/stores/colors';
import overridesStore from '@/stores/overrides';
import { permutationsWithRepetition } from '@/helpers/permutations';

const hasFillSelector = '*[fill]:not([fill="none"])';
const hasStrokeSelector = '*[stroke]:not([stroke="none"])';

function permutationCount(colors, overrides) {
  let itemCount = groupCount(overrides);
  let result;
  if (colors.length === 0 || itemCount === 0) {
    result = 0;
  } else {
    result = Math.pow(colors.length, itemCount);
  }

  return result;
}

// Find out how many actual groups the collection of overrides yields. The groups lay the
// foundation for producing permutations. A group may consist of one or more items.
function groupCount(overrides) {
  // `null` means that an item is excluded. Throw it away.
  let nonNullOverrides = overrides.filter(e => e !== null);

  let uniqueNonNullOverrides = new Set(nonNullOverrides);
  return uniqueNonNullOverrides.size;
}

function colorPermutations(colors, overrides) {
  return permutationsWithRepetition(colors, groupCount(overrides));
}

function* svgPermutations(svg, colorPermutations, overrides) {
  while (true) {
    let { value: colors, done } = colorPermutations.next();
    if (done) {
      break;
    }

    let clone = svg.cloneNode(true);
    // Apply colors sequentially to the clone, starting with fills and moving on to strokes
    let colorIndex = 0;
    let overrideIndex = 0;

    let groupColors = {};

    clone.querySelectorAll(hasFillSelector).forEach((node) => {
      let o = overrides[overrideIndex++];
      if (o === null) {
        return;
      }

      // If the group doesn't already have a color, make it this one.
      if (!groupColors[o]) {
        groupColors[o] = colors[colorIndex++];
      }

      // Apply the color to the attribute.
      node.setAttribute('fill', groupColors[o]);
    });

    clone.querySelectorAll(hasStrokeSelector).forEach((node) => {
      let o = overrides[overrideIndex++];
      if (o === null) {
        return;
      }

      if (!groupColors[o]) {
        groupColors[o] = colors[colorIndex++];
      }

      node.setAttribute('stroke', groupColors[o]);
    });

    yield clone;
  }
}

const svgPermutationsStore = derived(
  [prototypeStore, colorsStore, overridesStore],
  ([prototype, colors, overrides]) => {
    return {
      count: permutationCount(colors, overrides),
      generator: svgPermutations(
        prototype,
        colorPermutations(colors, overrides),
        overrides,
      ),
    }
  });

export default svgPermutationsStore;