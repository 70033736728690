<script>
  import ViewBar from "@/ViewBar.svelte";
  import Canvas from "@/Canvas.svelte";
  import Inspector from "@/Inspector.svelte";

  function didPaste(event) {
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    // Pass this data somewhere(?), if…
    // 1. SVG hasn't already been added
    // 2. No color input field is currently in focus
    console.log(`Pasted: "${paste}"`);
  }
</script>

<style>
  .layout {
    display: flex;
    height: 100%;
    min-height: 340px;
  }

  .sub-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 900px;
    height: 100%;
  }
</style>

<svelte:window on:paste={didPaste} />

<div class="layout">
  <div class="sub-layout">
    <ViewBar />
    <Canvas />
  </div>
  <Inspector />
</div>
