<script>
  import { createEventDispatcher } from "svelte";

  export let hex;

  const dispatch = createEventDispatcher();
  const change = () => dispatch("change", hex);
  const remove = () => dispatch("remove");
</script>

<style>
  .container {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .preview {
    margin-right: 8px;
    height: 16px;
  }

  input {
    width: 100%;
    height: 24px;
    background: #ffffff;
    border: 1px solid #e0e0e4;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 12px;
    padding: 0 8px 0 8px;
  }

  .remove {
    width: 8px;
    min-width: 8px;
    height: 8px;
    min-height: 8px;
    margin-left: 8px;
    background-image: url("/assets/icon-remove.svg");
    background-repeat: no-repeat;
    position: relative;
  }

  /* Touch Target Hack */
  .remove:before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -8px;
    left: -8px;
  }
</style>

<div class="container">
  <div class="preview">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill={hex} />
      <circle cx="8" cy="8" r="7.5" stroke="black" stroke-opacity="0.22" />
    </svg>
  </div>
  <input bind:value={hex} on:change={() => change()} />
  <div class="remove" on:click={() => remove()} />
</div>
