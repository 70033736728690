import { writable } from 'svelte/store';
import { parseToSVGDocumentElement } from '@/helpers/svgs';

const { subscribe, set } = writable();
const prototypeStore = {
  subscribe,
  set: (svgCode) => {
    set(parseToSVGDocumentElement(svgCode));
  },
};

export default prototypeStore;